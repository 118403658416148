.loading-spinner-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity as needed */
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999; /* Ensure the loading spinner is on top of other elements */
        img {
                clip-path: circle(29%);
                width: 250px;
            //     left: 42%;
                position: fixed;
            //     top:43%;
                opacity: 1;
            }
      }

// .loading-spinner {
//         border: 8px solid #f3f3f3;
//         border-top: 8px solid #3498db;
//         border-radius: 50%;
//         width: 125px;
//         height: 125px;
//         animation: spin 1s linear infinite;
//       }
      
// @keyframes spin {
//         0% { transform: rotate(0deg); }
//         100% { transform: rotate(360deg); }
//       }