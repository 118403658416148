.acheivements-container {
    background-color: #052941;
  width: 100%;
  .why-laulama {
    margin-top: 17%;

    h4 {
      font-weight: 400;
      font-size: 45px;
      span {
        font-weight: 600;
        font-size: 45px;
      }
    }
    span {
      font-weight: 400;
      font-size: 24px;
    }
  }
  .circle {
    .circle-container {
      display: flex;
      justify-content: center;
      .circle-border1,
      .circle-border2,
      .circle-border3,
      .circle-border4 {
        border-radius: 50%;
        width: 410px;
        height: 410px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }

      .circle-content
       {
        padding: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        p {
          font-weight: 600;
          font-size: 24px;
        }
        span {
          font-weight: 400;
          font-size: 16px;
        }
      }
      .circle-join2 {
        margin-left: -35px;
      }
    }
  }
}


@media (max-width: 1514px) {
  .acheivements-container{  
    .why-laulama {
      margin-top: 21%;
  
      h4 {
        font-weight: 400;
        font-size: 35px;
        span {
          font-weight: 600;
          font-size: 35px;
        }
      }
      span {
        font-weight: 400;
        font-size: 20px;
      }
    }    
  }    
}

@media screen and (max-width:1419px){
    .why-laulama{
        margin-top: 0px !important;
        margin-bottom: 40px !important;
    }
}

@media (max-width: 910px) {
  .why-laulama {
    margin-top: 0px;

    h4 {
      font-weight: 400;
      font-size: 30px !important;
      span {
        font-weight: 600;
        font-size: 30px !important;
      }
    }
    span {
      font-weight: 400;
      font-size: 22px !important;
    }
  }
  .acheivements-container .circle .circle-container {
    display: grid !important;
    justify-content: center;
  }
  .circle-join2 {
    display: grid;
    justify-content: center;
    .circle-border3 {
      margin-top: -35px;
    }
  }
}

@media (max-width: 650px) {
  .why-laulama {
    margin-top: 0px;

    h4 {
      font-weight: 400;
      font-size: 24px !important;
      span {
        font-weight: 600;
        font-size: 24px !important;
      }
    }
    span {
      font-weight: 400;
      font-size: 16px !important;
    }
  }
  .circle {
    .circle-container {
      flex-direction: column !important;
      align-items: center !important;
      .circle-border1,
      .circle-border2,
      .circle-border3,
      .circle-border4 {
        width: 350px !important;
        height: 350px !important;
      }

      .circle-content {
        padding: 15px !important;
        p {
          font-size: 20px !important;
        }
        span {
          font-size: 14px !important;
        }
      }

      .circle-join2 {
        margin-left: 0 !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .acheivements-container {
    .acheivements-section {
      .m-5 {
        margin: 2rem !important;
      }

      .p-5 {
        padding: 2rem !important;
      }
    }
    .why-laulama {
      margin-top: 0px;
      text-align: center;

      h4 {
        font-weight: 400;
        font-size: 25px !important;
        span {
          font-weight: 600;
          font-size: 25px !important;
        }
      }
      span {
        font-weight: 400;
        font-size: 16px !important;
      }
    }
    .circle {
      .circle-container {
        flex-direction: column !important;
        align-items: center !important;
        .circle-border1,
        .circle-border2,
        .circle-border3,
        .circle-border4 {
          width: 310px !important;
          height: 310px !important;
        }

        .circle-content {
          padding: 10px !important;
          p {
            font-size: 16px !important;
          }
          span {
            font-size: 12px !important;
          }
        }

        .circle-join2 {
          margin-left: 0 !important;
        }
      }
    }
  }
}

  @media (max-width: 450px) {
    .acheivements-container {
      .acheivements-section {
        .m-5 {
          margin: 2rem !important;
        }
  
        .p-5 {
          padding: 2rem !important;
        }
  
        .why-laulama {
          margin-top: 0px;
          text-align: center;
  
          h4 {
            font-weight: 400;
            font-size: 24px !important;
  
            span {
              font-weight: 600;
              font-size: 24px !important;
            }
          }
          span {
            font-weight: 400;
            font-size: 14px !important;
          }
        }
        .circle {
          .circle-container {
            flex-direction: column !important;
            align-items: center !important;
            .circle-border1,
            .circle-border2,
            .circle-border3,
            .circle-border4 {
              width: 280px !important;
              height: 280px !important;
              border-radius: 2px solid !important;
            }
  
            .circle-content {
              padding: 10px !important;
              p {
                font-size: 13px !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
              }
              span {
                font-size: 11px !important;
              }
            }
  
            .circle-join2 {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }

  @media (max-width: 460px) {
    .acheivements-container {
      .acheivements-section {
        margin: 0rem !important;
        padding: 2rem !important;
      }
    }
  }
  
@media (max-width: 385px) {
  .acheivements-container .acheivements-section {
    margin: 0rem !important;
    padding: 0rem 0rem 2rem 0rem !important;
  }
}
