.form-container {
    margin: 1.5rem 2rem;
}

.heading {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 25px;
}

.tabmenu {
    margin: 1rem 0rem;
}

// .p-tabmenu {
//     border-radius: 15px !important;
//     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
// }

.p-tabmenu .p-tabmenu-nav {
    // border: 0px !important;
    border-radius: 15px !important;
}

.p-tabmenu-nav .p-menuitem-text {
    line-height: 1.2 !important;
}


.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    width: 100%;
    min-height: 70px !important;
    // border-left: 1px solid rgba(5, 41, 65, 0.30)!important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    justify-content: center;
    font-size: 18px;
    color: #808080 !important;
    text-align: center;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background:  #ffffff !important;
    // border-color: #000000 !important;
    color: #3B8A6E !important;
    border-bottom: 3px solid #3B8A6E !important;
}

.p-tabmenuitem.p-highlight :focus {
    box-shadow: none !important;
}

.p-menuitem-link {
    border: 0px !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: inset 0 0 0 0.2rem #3b8a6e8c !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    color: #052941 !important;
}

.dialog-message {
    text-align: center !important;
    font-size: 18px !important;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    color: orange !important;
}

// .p-dialog .p-dialog-footer button {
//     width: 10% !important;
// }

// .p-dialog .p-dialog-footer button {
//     margin: 0 1.5rem 0 0 !important;
// }

.action-buttons {
    // color: black !important;
    // background: none !important;
    // padding: 10px !important;
    // border: 2px solid #052941 !important;
    display: none !important;
}

@media screen and (max-width:1400px) {
    .form-container .tabmenu .p-menuitem-text {
        font-size: 16px !important;
    }
}
