.GR-view {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .circular-progress-bar {
        width: 150px;
        height: 150px;
    }

    .circular-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .progressbars-section {
        border: 1.5px solid rgba(185, 185, 185, 1);
        padding: 0;

        .p-treetable table {
            width: -webkit-fill-available !important;
        }

        .p-treetable .p-treetable-thead>tr>th {
            color: rgba(255, 255, 255, 1) !important;
            background: rgba(5, 41, 65, 1) !important;
            text-align: center !important;
        }

        .p-datatable .p-datatable-thead>tr>th {
            color: rgba(255, 255, 255, 1) !important;
            background: rgba(5, 41, 65, 1) !important;
        }


        .p-treetable .p-treetable-tbody>tr>td {
            text-align: center !important;
            border: 1px solid #e9ecef;
            border-width: 1px;
            padding: 1rem 1rem 1rem 0 !important;

            &:first-child {
                text-align: left !important;
            }

            .col {
                padding-left: 1rem !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            .col-12 {
                padding-right: 20px !important;
            }
        }

        .p-treetable .p-treetable-tbody>tr[aria-expanded="true"][aria-level="1"]>td {
            border-width: 1px;
        }

        .p-treetable .p-treetable-tbody>tr[aria-level="2"]>td {
            border-width: 0;
            padding: 0.5rem 1rem 1rem 0 !important;

            .col-12 {
                padding: 0 !important;
            }
        }

        .p-treetable .p-treetable-tbody>tr[aria-level="2"] {
            border: 1px solid #e9ecef;
            height: 70px !important;
        }

        .p-treetable .p-treetable-tbody>tr[aria-level="3"]>td {
            border-width: 0;
            padding: 0.5rem 1rem 1rem 0 !important;

            .col-12 {
                padding: 0 !important;
            }
        }


        .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
            margin-left: 0;
        }
    }
}

.GR-Tabview {
    display: grid;
    gap: 1rem;

    .gr-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }

    .creditValue-section {
        display: grid;

        p {
            margin: 0;
            font-size: 16px;
        }

    }

    .progressbars-section {

        .p-treetable table {
            width: -webkit-fill-available !important;
        }

        .p-treetable .p-treetable-thead>tr>th {
            color: rgba(255, 255, 255, 1) !important;
            background: rgba(5, 41, 65, 1) !important;
        }

        .p-datatable .p-datatable-thead>tr>th {
            color: rgba(255, 255, 255, 1) !important;
            background: rgba(5, 41, 65, 1) !important;
        }


        .p-treetable .p-treetable-tbody>tr>td {
            text-align: left;
            border: 1px solid #e9ecef;
            border-width: 1px;
            padding: 1rem 1rem;
        }

        .p-treetable .p-treetable-tbody>tr[aria-expanded="true"][aria-level="1"]>td {
            border-width: 1px;
        }

        .p-treetable .p-treetable-tbody>tr[aria-level="2"]>td {
            border-width: 0;
        }
    }
}

.gr-section-table .hide-on-large-screens {
    display: none !important;
}

@media screen and (max-width: 1008px) {
    .gr-section-table .hide-on-large-screens {
        display: block !important;
    }

    .gr-section-table .hs-datatable {
        display: none !important;
    }

    .mobile-screen-hide-text {
        display: none !important;
    }

    .GR-Tabview .progressbars-section {
        .p-treetable .p-treetable-tbody>tr {
            border: 1px solid #e9ecef !important;
        }

        .p-treetable .p-treetable-tbody>tr[aria-level="2"]>td {
            padding: 0rem 0.5rem;
            border-width: 0px;
        }

        .p-treetable .p-treetable-tbody>tr[aria-level="3"]>td {
            padding: 0rem 0.5rem;
            border-width: 0px;
        }
    }
}

@media screen and (max-width: 490px) {
    .GR-Tabview {
        .gr-section {
            display: grid;
            justify-items: center;
            gap: 0;
        }

        .progressbars-section {
            margin-top: 20px;

            .p-datatable .p-datatable-thead>tr>th {
                padding: 7px !important;
            }
        }
    }
}