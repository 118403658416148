
.heading-values {
    width: 100%;
    height: auto;
    color: #052941 !important;
    font-size: 30px !important;
    margin: auto;
    padding: 0 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // text-align: center;
    margin-bottom: unset !important;
    font-weight: 100 !important;
    .values-content {
        span {
          font-family: Poppins;
          font-size: 30px;
          font-weight: 600;
          color: #000;
          display: flex;
          justify-content: flex-start;
        }
    
            p {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 400;
                color: #052941;
                // margin-bottom: 150px;
            }
        }
  }
  
  .main-value {
    width: 100%;
    height: auto;
    margin: auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
    padding: 2rem 2rem 3rem 7rem;
  
    .main-Sec {
      width: min-content;
      height: auto;
      display: flex;
      flex-direction: column;
  
      .half-circle {
        margin: auto;
        height: 90px;
        width: 290px;
        clip-path: ellipse(50% 100% at 50% 100%);
        position: absolute;
        left: -100px;
        transform: rotate(90deg);
      }
  
      .position {
        width: 100%;
        position: relative;
        margin: 3px 0px 3px 0px;       
      }
  
  
      .condent {
        width: auto;
        min-width: 300px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        position: relative;
        
        .ml-5{
            display: flex;
            flex-direction: column;
        }
        .values-icon img {
            width: 4rem;
            height: 4rem;
            color: rgba(5, 41, 65, 0.5);
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .values-head h2 {
            font-family: Poppins;
            font-size: 24px;
            color: #052941;
            padding: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .values-para span {
            color: #052941;
            font-family: Poppins;
            font-size: 16px;
            width: 70%;
            padding: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: start;
        }        
  
     
      }
    }
  }
  
  @media screen and (max-width: 924px) {
    .heading-values {
      padding: 0 20px !important;
      display: grid;

      .col-3 {
        width: 100%;
      }

      p {
        margin: 0;
        font-size: 28px;
      }

      .values-content {
        padding: 0.5rem;
      }
    }

    .main-value {
      padding: 0 0 0 2rem;
      gap: 20px;

      .main-Sec {
        .condent {
          width: 265px;
          min-width: auto;
        }
          .values-para span {
          width: 100% !important;
        }
      }
    }
  }

  @media (max-width: 700px) {
    .heading-values {
      justify-content: center !important;
      p {
        font-size: 22px !important;
      }
    }
  }

  @media (max-width: 500px) {
    .heading-values .values-content p {
      font-size: 16px !important;
    }
    .heading-values .values-content span {
      font-size: 24px !important;
    }
    .heading-values{
      padding: 1rem 40px;
    }
    .main-value {
      padding: 1rem 1rem 1rem 2rem;
    }
  }

