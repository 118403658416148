.banner-section {
    .banner-img {
        position: relative;

        img {
            height: 620px;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding-top: 100px;
            object-fit: cover;
        }

        .banner-content {
            position: absolute;
            top: 59%;
            left: 26%;
            transform: translate(-50%, -50%);
            background: rgba(5, 41, 65, 0.60);
            padding: 10px;
            width: 45%;

            .content-head {
                color: #FFF;
                font-family: Poppins;
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 20px 20px 0px 20px;
            }

            .content-para {
                p {
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 0px 20px 0px 20px;
                }
            }

            .banner-button {
                padding: 0px 20px 20px 10px;
                Button {
                    font-family: Poppins;
                    font-size: 18px;
                    text-align: left;
                }


            }
        }
    }
}


@media (max-width: 1500px) {
    .banner-section {
        .banner-content {
            width: 45% !important;
            padding: 10px 10px;

            .content-head {
                    font-size: 30px !important;
            }

            .content-para {
                p {
                    font-size: 20px !important;
                }
            }

            .banner-button {
                Button {
                    font-size: 16px !important;
                }


            }
        }
    }
}

@media (max-width: 800px) {
    .banner-section {
        .banner-content {
            left: 35% !important;
            width: 65% !important;

            .content-head {
                    font-size: 20px !important;
            }

            .content-para {
                p {
                    font-size: 16px !important;
                }
            }

            .banner-button {
                Button {
                    font-size: 16px !important;
                }


            }
        }
        .banner-img {
            img {
                height: 500px;
            }
        }
    }
}

@media (max-width: 500px) {
  .banner-section {
    .banner-img {
      img {
        height: 400px;
        // object-position: -276px 10%;
      }
    }

    .banner-content {
      left: 50% !important;
      padding: 10px !important;
      width: 80% !important;
      top:60% !important;
            .content-head {
                    font-size: 16px !important;
            }

      .content-para {
        p {
          font-size: 14px !important;
          display: none;
        }
      }

            .banner-button {
                padding-top: 10px !important;
                Button {
                    font-size: 14px !important;
                }


            }
        }
    }
}