.selected {
    border-radius: 10px;
    border: 3px solid #052941;
    background: #052941;
    color: #F9F9F9;
    .icon-img {
        filter: invert(100%) !important;
    }
}

.unselected {
    background: white;
}


.career-card-section {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    button {
        position: relative;
        width: 230px;
        height: 80px;
        border: 3px solid #052941;
        border-radius: 10px;
        gap: 10px;
        margin: 20px;
        font-family: 'Poppins', sans-serif !important;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}

div.view button {
    border: none;
    background: none;
    cursor: pointer;
    color: #1067B8;
    text-decoration: underline;
}

.text-box {
    display: grid;
}

.goal-panel .p-card .p-card-body {
    padding: 1rem !important;
}

.goal-panel .p-card {
    border-radius: 10px;
    border: 3px solid #808080;
    box-shadow: none !important;
    width: auto !important;

    .p-card-body {
        padding:  0.5rem 1rem !important;
    }

    .p-card-content {
        padding: 0px !important;
    }

    p {
        margin: 0px;
        color: #2F2E41;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    .radio-btn {
        margin: 0px 20px 20px 0px;
        justify-content: space-between;
    }

}

.goals-container .text-box {
    display: grid;
    gap: 20px;
}

.goals-container .text-section {
    margin-right: clamp(2rem, 4rem, 10rem);
    margin-left: clamp(2rem, 4rem, 10rem);
}

.icon-img {
    width: 3rem;
    height: 2rem;
    color: rgba(5, 41, 65, 0.5)
}

.footer-section {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.radio-label-text {
    font-size: 18px !important;
}
.careergoals-section{
    .desktop-career{
display: none !important;
}
}
.career-goals-section {
    display: none;
    .results-section {
        .heading {
            font-family: Poppins;
            font-size: 24px !important;
            font-weight: 600;
            line-height: 36px;
        }

        .paragraph {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
        }
        .core{
            padding-bottom: 0.9rem !important;
            .p-radiobutton{
                margin: 0.5rem !important;
            }
        }    
        .sub-heading {
            font-family: Poppins;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .riasec-section {
        .heading {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
        }

        .paragraph {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
        }
    }

    .res-career-section {
        .heading {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
        }

        .paragraph {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
        }
    }
}

.career-detail-sec {
    .heading {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
    }

    .paragraph {
        font-family: Poppins;
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 27px;
    }
}

.mobile-career {
    .results-section .core{
        padding-bottom: 1.2rem !important;
        .p-radiobutton{
            margin: 0.5rem !important;
        }
    } 
}

@media screen and (max-width:1600px) {
    .career-card-section {
        display: flex !important;
        justify-content: left;
        align-items: center;
    }
}

@media screen and (max-width:1400px) {
    .career-card-section {
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
    }
    .riasec-section{
        .gap-8 {
            gap: 2rem !important;
        }
    }
    .career-goals-section {
        .results-section {
            .heading {
                font-size: 22px !important;
            }
    
            .paragraph {
                font-size: 18px !important;
            }
    
            .sub-heading {
                font-size: 20px !important;
            }
        }
    
        .riasec-section {
            .heading {
                font-size: 22px !important;
            }
    
            .paragraph {
                font-size: 18px !important;
            }
        }
    
        .res-career-section {
            .heading {
                font-size: 22px !important;
            }
    
            .paragraph {
                font-size: 18px !important;
            }
        }
    }
    .career-detail-sec {
        .heading {
            font-size: 18px !important;
        }
    
        .paragraph {
            font-size: 16px !important;
        }
    }
    
    
}


@media screen and (max-width:1140px) {
    .careergoals-section {
        .career-card-section {    
        .career-card-section button {
            position: relative;
            width: 200px;
            height: 70px;
            gap: 5px;
            margin: 15px;
            font-size: 14px;
        }

        }
    }
}


@media screen and (max-width:1008px) {
    .career-goals-section {
        .results-section {
            .heading {
                font-size: 22px !important;
            }
    
            .paragraph {
                font-size: 18px !important;
            }
    
            .sub-heading {
                font-size: 18px !important;
            }
        }
    }
    .careergoals-section {
        .p-card {
            box-shadow: none !important;
        }

        .career-card-section button {
            width: 190px !important;
        }
        .m-5 {
            margin: 1rem !important;
        }
        .riasec-section{
            .gap-8 {
                gap: 1rem !important;
            }
            .heading {
                font-size: 22px !important;
            }
    
            .paragraph {
                font-size: 18px !important;
            }
        }
       
    }
    .career-marks {
        display: grid !important;

        .col-3 {
            width: auto !important;
        }
    }
    .careergoals-section .ml-6 {
        margin-left: -2rem !important;
    }
    .careergoals-section .ml-7 {
        margin-left: -2rem !important;
    }
    .careergoals-section .mr-7 {
        margin-right: -2rem !important;
    }

}

@media screen and (max-width:890px) {
    .careergoals-section {
        .career-card-section {
            display: flex !important;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap:wrap !important ;
        }

        .icon-img {
            display: none;
        }

        .results-section .mt-7{
            margin-top: 1rem !important;
        }
    }
}

@media screen and (max-width:400px) {
    .careergoals-section .ml-6 {
        margin-left: -3rem !important;
    }
    .careergoals-section .ml-7 {
        margin-left: -3rem !important;
    }
    .careergoals-section .mr-7 {
        margin-right: -3rem !important;
    }
    .careergoals-section .p-button {
        font-size: 16px !important;
        padding: 0.5rem 0.5rem !important;
    }

    .career-goals-section .mb-3 {
        margin-bottom: 2rem !important;
    }
}

.accordion-content {
    .drops-sections {
        .dropdown-section {
            width: 50% !important;

        }

        .radio-section {
            width: 50% !important;
        }
    }
}

@media screen and (max-width:1250px) {
    .accordion-content {
        
        .radio-section {
        .gap-1 {
            gap: 0.7rem !important;
        }
    }
    }
}
@media screen and (max-width:1100px) {
    .drops-sections {
            .driver-sec {
                display: grid !important;
            }
        }
    .accordion-content {
        .radio-section {
        .gap-1 {
            gap: 2.25rem !important;
        }
    }
    }
}

@media screen and (max-width:900px) {
    .accordion-content {
        .drops-sections {
            display: grid !important;

            .dropdown-section {
                width: 100% !important;
                border-right-width: 0px !important;
            }

            .radio-section {
                width: 100% !important;
                .gap-1 {
                    gap: 0rem !important;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .careergoals-section {
        .career-card-section button {
            width: 150px !important;
            margin: 0;
            font-size: 16px;
        }
    }
    .mobile-career {
        .results-section .core{
            padding-bottom: 0.9rem !important;
            .p-radiobutton{
                margin: 0.5rem !important;
            }
        } 
    }
    .accordion-content {
        .gap-8 {
            gap: 1rem !important;
        }
    }
}

@media screen and (max-width:600px) {
    .accordion-content {
        .drops-sections {
            .driver-sec {
                display: grid !important;

                label {
                    width: 100% !important;
                    font-size: 16px !important;
                }

                .p-inputtext {
                    font-size: 16px !important;
                }
            }

            .radio-section {
                label {
                    font-size: 16px !important;
                }

                .ml-8 {
                    margin-left: 0rem !important;
                }

                .gap-3 {
                    gap: 0rem !important;
                }

                .radio-secs {
                    margin: 0.5rem 0rem 0.5rem 0rem !important;
                }
            }
        }
    }

    .mobile-career .p-accordion .p-accordion-content {
        padding: 0rem !important;
    }
}

@media screen and (max-width:490px) {
    .goal-panel .p-card p {
        font-size: 18px !important;
    }
    .radio-label-text {
        font-size: 16px !important;
    }
    .careergoals-section {
        .ml-4 {
            margin-left: 0 !important;
        }

        .mr-5 {
            margin-right: 0 !important;
        }

    }

    .accordion-content {
        .paragraph {
            font-size: 18px !important;
            a{
                font-size: 18px !important;
            }
        }
    }
    .mobile-career {
        display: block;
        .results-section {
            .m-4 {
                margin: 0.5rem !important;
            }
            .px-4 {
                padding-left: 1rem !important;
                padding-right: 1rem !important;
            }
        }
    }
    
}