.laulamahistory-section {
    .history-heading {
        p {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(0, 0, 0, 1);
            border-bottom: 4px solid #91BF82;
        }
    }

    .history-para {
        p {
            font-family: Poppins;
            font-size: 20px;
            color: #000000;
            letter-spacing: normal;
        }
    }


    .anatomy-heading {
        p {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(0, 0, 0, 1);
            border-bottom: 4px solid #91BF82;
        }
    }

    .anatomy-logo-section {
        .logo-torch {
            width: 80%;

            .logo-description {
                p {
                    font-family: Poppins !important;
                    font-size: 20px !important;
                }
            }
        }

        .logo-section {
            img {
                width: 30rem !important;
                height: 10rem !important;
            }
        }

        .logo-laulama {
            width: 50%;
            p {
                font-family: Poppins !important;
                font-size: 20px !important;
            }
        }

        .logo-schools {
            width: 38%;
            p {
                font-family: Poppins !important;
                font-size: 20px !important;
            }
        }
    }
}

.sponsership {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.p-sponsership {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.s-partners {
    color: #000;
    font-family: Lustria;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 1600px) {
    .anatomy-logo-section {
        .logo-schools {
            width: 40% !important;
            margin-top: -50px;

        }
    }

    .laulamahistory-section .anatomy-logo-section .logo-section img {
        width: 25rem !important;
        height: 8rem !important;
    }
}


@media (max-width: 1400px) {
    .laulamahistory-section {
        .m-7 {
            margin: 2rem !important;
        }

        .history-section {
            display: grid !important;

            .col-9 {
                width: 100% !important;
            }

            .history-heading {
                width: 100% !important;
            }

            .history-para {
                p {
                    font-size: 18px !important;
                }
            }
        }

        .anatomy-logo-section {
            .logo-torch {
                width: 80% !important;

                .dotted {
                    margin-bottom: -3rem !important;
                }
                
                .logo-description {
                    p {
                        font-size: 18px !important;
                    }
                }
            }

            .logo-laulama {
                width: 55% !important;
                p {
                    font-size: 18px !important;
                }
            }

            .logo-schools {
                width: 30% !important;
                margin-top: -30px;
                p {
                    font-size: 18px !important;
                }
            }
        }


        .anatomy-section {
            display: grid !important;

            .col-9 {
                width: 100% !important;
            }

            .anatomy-heading {
                width: 100% !important;
            }

        }

    }
}

@media (max-width: 1200px) {
    .laulamahistory-section .anatomy-logo-section .logo-schools {
        width: 37% !important;
        margin-top: -50px;

    }
}

@media (max-width: 1008px) {
    .Laulamaschools {
        display: none;
    }

    .logo-section {
        .anatomy-para {
            display: grid !important;

            p {
                font-size: 18px;
                text-align: justify;
            }

            img {
                height: 3rem;
                width: 12rem;
            }
        }
    }

    .laulamahistory-section {
        .history-section {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
        .history-heading {
            padding: 0 !important;
            p {
                font-size: 20px !important;
                margin: 0 !important;
            }
        }

        .history-para {
            p {
                font-size: 16px;
                text-align: justify;
            }
        }

        .anatomy-section .anatomy-heading p {
            font-size: 20px !important;
        }
    }
}


@media (max-width: 500px) {
    .laulamahistory-section {
        .m-7 {
            margin: 1rem !important;
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
        .history-section .history-para p {
            font-size: 16px !important;
            letter-spacing: normal !important;
        }

        .anatomy-section {
            margin-top: 0 !important;
        }
    }
    .logo-section {
        .anatomy-para {

            p {
                font-size: 16px;
            }
        }
    }
}