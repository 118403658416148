.head {
    nav {
        position: fixed;
        z-index: 99;
        width: 100%;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    nav .wrapper {
        position: relative;
        padding: 0px 30px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
    }

    nav .wrapper .logo img {
        width: 185px;
    }

    .wrapper .nav-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-links li {
        list-style: none;

        // z-index: 99;
        &:last-child {
            padding: 9px 0px 9px 15px;
        }
    }

    .nav-links li a {
        color: #000;
        text-decoration: none;
        font-size: 18px;
        padding: 9px 15px;
        border-radius: 5px;
        transition: all 0.3s ease;
        display: flex;
        gap: 10px;
    }

    .nav-links .mobile-item {
        display: none;
    }

    li:hover .aboutus-submenus,
    li:hover .system-submenus,
    li:hover .product-submenus,
    li:hover .stories-submenus {
        transition: all 0.3s ease;
        top: 75px;
        opacity: 1;
        visibility: visible;
    }

    .aboutus-submenus,
    .system-submenus,
    .product-submenus,
    .stories-submenus {
        position: absolute;
        right: 0;
        width: 70%;
        padding: 0 30px;
        top: 85px;
        opacity: 0;
        visibility: hidden;
    }

    .content {
        background: #fff;
        padding: 50px 50px 50px 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
    }

    .row {
        // width: calc(35% - 30px);
        width: calc(50% - 30px);
        height: fit-content;

        p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }

        img {
            width: 100%;
            height: 350px;
            object-fit: cover;
            border-radius: 0px 100px;
            object-position: center;
        }
    }

    .aboutus-submenus .row {

        header {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 4px solid #EA8254;
        }

        .mega-links li a:hover {
            color: #fff;
            background: #3b8a6e;
            font-weight: 600;
        }

    }

    .system-submenus .row {

        header {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 4px solid #3B8A6E;
        }

        .mega-links li a:hover {
            color: #fff;
            background: #052941;
            font-weight: 600;
        }

    }

    .product-submenus .row {
        header {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 4px solid #052941;
        }

        .mega-links li a:hover {
            color: #fff;
            background: #FC5145;
            font-weight: 600;
        }

    }

    .stories-submenus .row {

        header {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 4px solid #EBB448;
        }

        .mega-links li a:hover {
            color: #fff;
            background: #EA8254;
            font-weight: 600;
        }

    }

    .row:first-child {
        display: flex;
        align-items: center;
        line-height: 50px;
    }

    .row .mega-links ul {
        padding: 0;
    }

    .row .mega-links li {
        padding: 5px 0;
    }

    .row .mega-links li a {
        padding: 0px;
        padding: 0 20px;
        color: #0f0c0c;
        font-size: 17px;
        display: block;
        border-radius: 30px;
    }
}

@media screen and (max-width: 1220px) {

    .head .wrapper .nav-links {
        align-items: flex-start;
        padding-left: 20px;
        line-height: 40px;
        padding: 50px 10px;
        flex-direction: column;
        margin: 0;
    }

    .menu-toggle {
        display: block;
    }

    .menu {
        display: none;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 100px;
        background: #033e5a;
        left: 0;
        text-align: center;
    }

    .menu.show-menu {
        display: block;
        border-top: 2px solid white;
    }

    .menu li {
        margin-bottom: 0.5rem;
        cursor: pointer;
    }

    .menu a {
        color: #fff !important;
        text-decoration: none;
        align-items: center !important;
    }

    .head .row {
        width: 100% !important;
    }

    .head .content {
        background: none !important;
        padding: 0 !important;
    }

    .nav-links {
        list-style-type: none;
        display: flex;

        // li:last-child {
            // padding: 9px 0px 9px 0px !important;
        // }

    }

    .submenuItems {
        button {
            font-size: 18px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            line-height: 25px;
            background: none;
            color: white;
            cursor: pointer;
            padding: 5px 10px;

            svg {
                margin-right: 5px;
            }
        }

        ul {
            margin: 20px;

            li {
                margin-left: 40px;
            }
        }
    }

}

@media screen and (max-width: 970px) {

    .head .wrapper .nav-links {
        align-items: flex-start;
        padding-left: 20px;
        line-height: 40px;
    }

    .menu-toggle {
        display: block;
    }

    .menu {
        display: none;
        position: absolute;
        width: 100%;
        height: 700px;
        top: 100px;
        background: #033e5a;
        left: 0;
        text-align: center;
        overflow: auto;
    }

    .menu.show-menu {
        display: block;
        border-top: 2px solid white;
        height: 100vh;
    }

    .menu li {
        margin-bottom: 0.5rem;
        cursor: pointer;
    }

    .menu a {
        color: #fff !important;
        text-decoration: none;
        align-items: center !important;
        text-align: left;

    }

    .wrapper .nav-links {
        padding: 50px 10px;
        flex-direction: column;
    }

    .head .row {
        width: 100% !important;
    }

    .head .content {
        background: none !important;
        padding: 0 !important;
    }

    .nav-links {
        list-style-type: none;
        display: flex;
    }

    .submenuItems {
        button {
            font-size: 18px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            line-height: 25px;
            background: none;
            color: white;
            cursor: pointer;
            padding: 5px 10px;

            svg {
                margin-right: 5px;
            }
        }

        ul {
            margin: 20px;

            li {
                margin-left: 40px;
            }
        }
    }

}

.active-menu-item {
    font-weight: bold;
}

.icon {
    position: relative;
    top: 3px;
}