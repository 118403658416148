.contact-us {
    padding-top: 6rem !important;
}

.contact-left-section {
    padding: 2rem !important;
}
.header-image {
    text-align: center;
}

.contact-right-section {
    padding: 1rem 2rem !important;
}

.info {
    padding: 0rem 4rem;
}

.letsTalk{
    color: #000;
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contactus-img {
    padding: 2rem;
    width: 100%;
}

.calendly-component {
    margin-top: 10px;
}

.client-form {
    padding: 1rem 2rem;
}

.cu-sub-text {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3rem;
}

.email-info {
    padding-top: 20px;
} 

.emailname {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
}

.email-id {
    color: #000;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    margin-bottom: 2rem;
}

.socials {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
}

.social-profile {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-bottom: 0.5rem;
}

.contactus-checkbox {
    margin: 5px 20px 0px 5px !important;
}

.contactus-footer-buttons {
    margin-top: 20px;
    padding: 20px 0px;
    display: flex;
    justify-content: right;
    border-top: 1px solid rgba(0, 0, 0, 0.16);;
}

@media screen and (max-width: 500px) {
    .info {
        padding: 1rem;
    }
    .letsTalk {
        font-size: 40px !important;
    }
    .contact-left-section {
        padding: 1rem !important;
    }
    .emailname {
        font-size: 25px;
    }
    .calendly-button {
        font-size: 20px !important;
    }
    .socials {
        font-size: 25px;
    }
}