.extra-activities-form {
    margin: 1rem 1rem 4rem 1rem;
}

.header-text {
    color: #000000;
    font-size: 24px;
}

.Extra-activities-form-footer{
    margin: 2rem 0;
    display: flex;
    justify-content: flex-end;
}

.addextra-icon{
    color: #1067B8;
    font-size: 16px !important;
}

.addextra-text{
    color: #1067B8;
    font-size: 16px !important;
}

.extra-activities-form .p-datatable .p-datatable-thead > tr > th{
    background: #052941 !important;
    color: #FFFF !important;
    border: 1px solid #B9B9B9 !important;
    .p-datatable .p-datatable-tbody > tr > td {
        border: 1px solid #B9B9B9 !important;
    }
    
}



.extra-activities-form .p-datatable {
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.extraactivities-section {
    .pi-plus {
        font-size: 24px !important;
    }
}

.desktop-activities{
    display: none;
}

@media screen and (max-width:1008px) {
    .extraactivities-section {
        .p-card {
            box-shadow: none !important;
        }
        .m-4{
            margin: 0px !important;
        }
    }
    .extra-activities-form {
        margin:0rem !important;
    }
}

@media screen and (max-width:500px) {
    .extraactivities-section {
        .pi-plus {
            font-size: 18px !important;
        }
    }
}

@media screen and (max-width:767px) {
    .accordion-content{
        .teacher-notes-section{
            p{
                font-size: 18px;
            }
        }
    }
}