.student-progress_report-container {
    margin-top: 8rem !important;

    .p-button.p-button-info {
        color: #1575E5 !important;
    }
}

.spr-back-button {
    color: #1575E5 !important;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px 20px;
}

.spr-header {
    margin: 0px 40px;
    align-items: center;
}

.legend {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 1rem !important;
}

.l-name {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.l-list {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 0px;
}

.p-tooltip .p-tooltip-arrow {
    border-right-color: #585858;
}

.p-tooltip .p-tooltip-text {
    background-color: #585858;
    color: #fff;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: none;
}

.p-tooltip {
    max-width: 200px;
}

.spr-datatable {
    .p-datatable {
        width: 100% !important;
        table-layout: auto;
    }

    .p-datatable .p-datatable-thead>tr>th {
        background-color: #052941 !important;
        color: #FFFFFF !important;
        font-family: Poppins !important;
        box-shadow: none !important;
        border: 1px solid #B9B9B9 !important;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        font-family: Poppins !important;
        border: 1px solid #B9B9B9 !important;
        min-width: 0px !important;
        max-width: 0px !important;
        padding: 12px !important;
    }

    .p-datatable .p-datatable-thead>tr>th:first-child {
        border-top-left-radius: 10px;
        /* Adjust the value as needed */
    }

    /* Optional: Add styles for other corners if desired */
    .p-datatable .p-datatable-thead>tr>th:last-child {
        border-top-right-radius: 10px;
    }

    // .p-datatable .p-datatable-tbody>tr:last-child>td:first-child {
    //     border-bottom-left-radius: 10px;
    // }

    // .p-datatable .p-datatable-tbody>tr:last-child>td:last-child {
    //     border-bottom-right-radius: 10px;
    // }
}

.column-fixed-width {
    width: 20px !important;
    height: 20px !important;
}

.download-content {
    overflow: auto;
    /* Ensure no content is hidden */
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 490px) {
    .spr-datatable {
        .p-datatable .p-column-header-content {
            padding: 20px !important;
        }
    }
}
