.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.inputTextFeild {
  width: 100%;
  height: 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  label,
  .p-inputtext {
    width: 80%;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pie-chart-container .pie-card-section .p-disabled, .p-disabled * {
  cursor: not-allowed !important;
  pointer-events: visible !important;
  -webkit-user-select: none !important;
  user-select: none !important;
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}