.extra-activities-section {
  .extra-image {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }
  .p-datatable .p-datatable-tbody > tr > td {
    color: black;
    border: 1px solid #B9B9B9 !important;
  }
  .date{
    .p-button {
      padding: 0px !important;
  }
}
}

.addextra-text {
  font-size: 18px !important;
  cursor: pointer;
  text-align: right;
}

@media (max-width: 1008px) {
  .datatable-container {
    display: none;
  }
 .extra-activities-section{
  .mobile-extraactivities-field{
    display: grid !important;
    .lg\:col-4{
      width: 100% !important;
    }
    .lg\:col-3{
      width: 100% !important;
    }
  }
    .mobile-work-field{
      .lg\:col-4{
        width: 100% !important;
      }
    }
  .mobile-community-field{
    .lg\:col-3{
      width: 100% !important;
    }
  }

  .notes-alignment {
    display: flex;
    flex-direction: column;

    .col {
      font-size: 18px;
    }
  }
 }
  
  .extra-activities-tables {
    ul {
      li {
        list-style: none;
        border-radius: 10px !important;
        background: #fff !important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
        margin: 10px;
      }

      .activity-heading {
        background: rgba(234, 130, 84, 0.3) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 10px !important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
      }
    }

    .hide-on-large-screens {
      padding: 0px !important;
    }
  }
  .ex-p {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .extra-heading .positionHeld {
    width: 100% !important;
  }
  .extra-heading .startYear {
    width: 100% !important;
  }
  .extra-heading .endYear {
    width: 100% !important;
  }
  .extra-heading .totalHours {
    width: 100% !important;
  }
  .description {
    width: 100% !important;
  }
  
  .extra-activities-section .notes-alignment {
    padding-top: 0 !important;
  }
}

@media (max-width: 500px) {
  .extra-activities-tables ul .activity-heading {
    font-size: 16px;
  }
  ul {
    font-size: 16px;
    .py-4 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    .px-4 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  }
  .extra-activities-section {
    .extra-heading {
      font-size: 16px;
    }
  }
  .ex-p {
    font-size: 16px;
  }
  .extra-heading .positionHeld {
    width: 100% !important;
  }
  .extra-heading .startYear {
    width: 100% !important;
  }
  .extra-heading .endYear {
    width: 100% !important;
  }
  .extra-heading .totalHours {
    width: 100% !important;
  }
  .description {
    width: 100% !important;
  }
}
