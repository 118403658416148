.faq-page {
    padding-top: 130px;
}

.searchrelated-section {

    svg:not(:root).svg-inline--fa,
    .container-section svg:not(:host).svg-inline--fa {
        color: #91BF82 !important;
    }

    .accordion-header {
        font-size: 20px !important;
        line-height: normal !important;
        font-weight: bold !important;
        color: #000000 !important;
        display: flex;
        align-items: center;
    }
    .p-accordion .p-accordion-content {
        padding: 10px !important;
        font-size: 18px !important;
        color: #052941 !important;
    }
}

.container-section {
    // overflow-y: auto;
    // max-height: 100vh;
    // margin-top: 110px;
    padding: 2rem 3rem;

    .p-accordion .p-accordion-header .p-accordion-header-link {
        font-size: 1.3rem !important;
        padding: 1rem 1rem 1rem 0rem !important;
    }
    
    .p-accordion .p-accordion-content {
        padding: 10px !important;
        font-size: 18px !important;
        color: #052941 !important;
    }

    .accordion-header {
        font-size: 20px !important;
        font-weight: bold !important;
        color: #000000 !important;
        display: flex;
        align-items: center;
    }

    .faq-heading-section {
        margin: 2rem;
    }
    .faq-heading {
        font-family: Poppins;
        font-size: 20px !important;
        font-weight: bold;
        border-radius: 10px !important;
    }

    .faq-sub-headings {
        h2 {
            font-family: Poppins;
            font-size: 28px;
            color: #052941 !important;
        }
    }

    svg:not(:root).svg-inline--fa,
    svg:not(:host).svg-inline--fa {
        color: #91BF82 !important;
    }
}

.header-section {
    margin: 40px;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
}

.search-section {
    background-color: #052941;
    height: 340px;
    // margin-top: 110px;

    h2 {
        color: #FFF;
        display: flex;
        place-content: center;
        // top: 30%;
        position: relative;
        font-size: 2rem;
    }

    .faq-search-section {
        // top: 35%;
        position: relative;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .search-box {
        top: 35%;
        position: relative;
        justify-content: flex-start;
        display: flex;
        align-items: center;
    }

    .search-icon {
        position: absolute;
        cursor: pointer;
        color: #052941;
        font-size: 18px;
        font-weight: bold;
        padding: 2rem
    }

    input[type="text"] {
        padding: 15px;
        width: 600px;
        border-radius: 10px;
        font-family: Poppins;
        font-size: 16px;
        padding-left: 55px;
    }
}

.text-boxes {
    border-radius: 10px;
    border: 1px solid #91BF82;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.highlighted {
    background-color: yellow;
}

.faq-sub-headings .p-accordion.p-component {
    border-top: 4px solid #91BF82 !important;
}

@media (max-width:800px) {
    .faq-heading-section {
        margin: 1rem !important;
    }
}

@media (max-width: 767px) {
    
    .container-section .flex{
        display: grid !important;
        gap: 20px !important;
    }
    .search-section{
        input[type="text"] {            
            width: 400px !important;           
        }
        h2 {
            font-size: 22px;
        }
    }

    .container-section {
        .faq-heading-section .faq-heading {
            font-size: 18px !important;
        }

        .faq-sub-headings h2 {
            font-size: 20px !important;
            padding: 1rem 0rem !important;
        }

        .accordion-header {
            font-size: 16px !important;
            line-height: normal !important;
        }

        .p-accordion .p-accordion-content {
            font-size: 16px !important;
        }
        .header-section {
            font-size: 20px;
        }
    }

    .searchrelated-section {
        h2 {
            font-size: 18px;
        }

        .accordion-header {
            font-size: 16px !important;
            line-height: normal !important;
        }
        .p-accordion .p-accordion-content {
            font-size: 16px !important;
        }
    }
}

@media (max-width: 500px) {
    .search-section{
        input[type="text"] {            
            width: 300px !important;           
        }
    }

    .container-section {
        padding: 2rem 2rem !important;
    }

}

// border-bottom: 4px solid #91BF82 !important;