.title-name {
  background: white;
  color: #000000;
  border-bottom: 1px solid #e9ecef;
  line-height: 36px;
  font-size: 24px;
  text-align: left;
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding-bottom: 20px;
}

.viewlist-container {
  width: 100%;
  .content-field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.16rem;
    border-bottom: 1px solid #EDE2E2;
  }
  .name-list {
    padding-left: 20px;
  }
}
.load-more {
  display: flex;
  justify-content: center;
  line-height: 36px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 20px;
  a {
    text-decoration: none;
    color: #1575e5;
  }
  
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: rgba(145, 191, 130, 0.1) !important;
}

.p-datatable .p-datatable-thead > tr > th { 
  // border-bottom:3px solid rgba(0, 0, 0, 0.25) !important;  
  box-shadow: inset 0px -4px 0px 0px rgba(1, 1, 1, 0.1);
  color:#000000 !important;
   background:none !important;  
  // border: 1px solid; 
  padding: 1rem 0.5rem 1rem !important;
  font-size: 18px !important;
}
:root .p-column-filter-menu {
  display: inline-flex;
   margin-left: 10px !important; 
}
.p-dataview-emptymessage {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-bottom: none !important;
  padding-top: 15rem !important;
  font-size: 18px !important;
}

.student-dt {
  .search-field {
    h3 {
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 1050px) {
  .title-name {
    font-size: 20px;
    padding-bottom: 15px;
  }
  .viewlist-container .content-field {
    padding: 1.16rem 0rem;
}
}
@media only screen and (max-width: 1008px) {
  .p-dataview-emptymessage {
    padding-top: 3rem !important;
  }
}
@media only screen and (max-width: 940px){
.title-name {
    font-size: 18px;
    padding-bottom: 10px !important;
}
.load-more {
  padding-top: 15px !important;
}
.viewlist-container .content-field {
  padding: 1rem 0rem;
}
}
.p-column-filter-overlay-menu .p-column-filter-operator {
  display: none !important;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  display: none !important;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  display: none !important;
}

.p-column-header-content {
  justify-content: space-evenly !important;
}

@media (max-width: 460px) {
  .p-datatable .p-datatable-thead > tr > th {
    padding: 0rem 0rem !important;
  }
  .student-dt {
    margin-top: -10px;
    .search-field {
      margin: 1rem !important;
      .p-input-icon-right {
        width: 164px !important;
      }
    }
    .card-field {
      .p-card .p-card-body {
        padding: 0rem !important;
      }
    }
  }
}

@media (max-width: 320px) {
  .search-field {
    .p-input-icon-right {
      width: 164px !important;
    }
  }
}

.Student-datatable{
  margin-top: 110px !important;
  
  .p-card .p-card-content {
    padding: 0 !important;
  }
}

.Teacher-datatable{
  margin-top: 110px !important;

  .card-section{
    .spinner-section {
      .spinner-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
      }
      @keyframes spin {
          0% {
              transform: rotate(0deg);
          }

          100% {
              transform: rotate(360deg);
          }
      }
      .p-progress-spinner-circle {
          stroke: #052941 !important;
          stroke-width: 5 !important;
          animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
      }
  }
  }
}