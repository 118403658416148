.error-container {
  background-color: #ffe7e6;
  border-radius: 15px;
}
.error-head {
  font-weight: 700;
  font-size: 16px;
  color: #660000;
}
.error-messages {
  font-weight: 400;
  font-size: 16px;
  color: #660000;
}

.success-container {
  background-color: #d6f5db;
  border-radius: 15px;
}
.success-head {
  font-weight: 700;
  font-size: 16px;
  color: #365e3d;
}
.success-messages {
  font-weight: 400;
  font-size: 16px;
  color: #365e3d;
}
