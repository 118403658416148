.principal-cards {
    margin: 2rem !important;
    margin-top: 120px !important;
}

.principal-dashboard{
    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }
}

@media only screen and (max-width: 1100px) {
.principal-cards {
    margin: 1rem !important;
}
}
@media (max-width: 1008px) {
    .principal-dashboard .col-4 {
        width: 100% !important;
}

    .principal-dashboard .col-8 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1108px) {
    .principal-cards {
        margin-top: 110px !important;
    }
}