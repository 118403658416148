.textdecor {
    color: #1575E5;
    font-size: 18px;
    // margin: 10px 0;
    display: flex;
    align-items: center;
    padding-bottom: 30px;

    svg {
        font-size: initial;
    }
}

.textdecor-button {
    color: #1575E5;
    font-size: 18px;
    margin: 10px 0;
}

.table-section {
    margin-top: 0px;
    margin-bottom: 0px;

    .p-card {
        padding: 0 !important;

        .p-card-content {
            padding: 0 !important;
        }
    }


    .p-card-body {
        padding: 0 !important;
    }


    .p-datatable .p-datatable-thead>tr>th {

        background: #FFF;
        box-shadow: inset 0px -4px 0px 0px rgba(1, 1, 1, 0.1);
        padding: 1.5rem;
        text-overflow: ellipsis !important;
        font-size: 18px;
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
    }

    .p-datatable .p-datatable-thead>tr>th:first-child {
        width: 75px !important;
        max-width: 70px !important;
        min-width: 50px !important;
        border-radius: 10px 0px 0px 0px;
    }

    .p-datatable .p-datatable-thead>tr>th:last-child {
        border-radius: 0px 10px 0px 0px;
    }

    .p-datatable .p-datatable-tbody>tr>td:first-child {
        width: 75px !important;
        max-width: 70px !important;
        min-width: 50px !important;
        overflow: visible;
    }

    .p-datatable .p-datatable-thead>tr>th:nth-child(2) {
        max-width: 120px !important;
        min-width: 120px !important;
        overflow: visible;
    }

    .p-datatable .p-column-header-content {
        justify-content: center !important;
    }

    .hide-header.p-datatable .p-column-header-content {
        justify-content: left !important;
        padding-left: 20px;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        text-align: left !important;
        border: 1px solid #e9ecef;
        border-width: 0 1px 1px 0;
        padding: 1.5rem;
        font-size: 18px;
        text-overflow: ellipsis !important;
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
    }

    .hide-header.p-datatable .p-datatable-thead>tr>th {
        box-shadow: none;
    }

    .hide-header.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
        border-width: 0;
    }

    .p-datatable .p-datatable-tbody>tr>td:last-child {
        width: 140px !important;
        max-width: 120px !important;
        min-width: 100px !important;
        padding: 1.5rem 0 !important;
    }

    .p-datatable .p-datatable-thead>tr>th:last-child {
        width: 80px !important;
        max-width: 80px !important;
        min-width: 80px !important;
    }

    @media screen and (min-width: 1920px) {
        .hide-header.p-datatable .p-datatable-tbody>tr>td {
            max-width: 800px !important;
        }

        .hide-header.p-datatable .p-datatable-tbody>tr>td:last-child {
            max-width: 100px !important;
            width: 100px !important;
            min-width: 100px !important;
        }

        .hide-header.p-datatable .p-datatable-tbody>tr>td:first-child {
            width: 50px !important;
            max-width: 150px !important;
            min-width: 150px !important;
            overflow: visible;
        }

        .hide-header .p-datatable .p-column-header-content {
            width: 200px !important;
        }
    }

    .p-datatable.p-datatable-gridlines .p-paginator-bottom {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    @media screen and (min-width: 1240px) {
        .hide-header.p-datatable .p-datatable-tbody>tr>td {
            width: 300px !important;
            max-width: 190px !important;
        }

        .hide-header.p-datatable .p-datatable-tbody>tr>td:last-child {
            max-width: 120px !important;
        }

        .p-datatable .p-datatable-tbody>tr>td {
            // max-width: 200px !important;
            max-width: 150px !important;
            width: 300px !important;

        }

        .p-datatable .p-datatable-tbody>tr>td:nth-child(2) {
            max-width: 160px !important;
            min-width: 135px !important;
            overflow: visible;
        }

        .table-section .hide-header.p-datatable .p-datatable-tbody>tr>td:first-child {
            width: 50px !important;
            max-width: 50px !important;
            min-width: 50px !important;
            overflow: visible;
        }

        .hide-header.p-datatable .p-datatable-tbody>tr>td:first-child {
            width: 50px !important;
            max-width: 50px !important;
            min-width: 50px !important;
            overflow: visible;
        }
    }

    .hide-header.p-datatable .p-datatable-tbody>tr>td:first-child {
        width: 50px !important;
        max-width: 150px !important;
        min-width: 110px !important;
        overflow: visible;
    }


    .hide-header.p-datatable .p-datatable-tbody>tr>td:nth-child(2) {
        max-width: 250px !important;
        min-width: 100px !important;
        overflow: visible;
    }

    .p-datatable .p-datatable-tbody>tr>td:nth-child(2) {
        max-width: 50px !important;
        min-width: 50px !important;
        overflow: visible;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td:last-child {
        border-width: 0 1px 1px 0px;
        display: flex;
        padding: 20px !important;
        min-width: 100% !important;
    }

    .p-row-odd>td {
        background: rgba(145, 191, 130, 0.10);
    }

    .p-column-filter-menu-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem #052941;
    }
}

.cards {
    margin: 2.5rem !important;
}

.admin-section {
    margin-top: 135px !important;
    margin: 0 5rem;

    h2 {
        margin: 0;
    }
}

.form-section {
    margin-top: 130px !important;
    margin: 0 3rem;
}


@media screen and (max-width:500px) {

    .admin-groups-container.m-6 {
        margin: 1rem !important;

    }

    .group-img-section {
        img {
            width: 280px;
        }
    }
}

.pie-chart-section {
    .pie-chart {
        box-sizing: border-box;
        display: block;
        height: 355px !important;
        width: 355px !important;
    }
}

@media screen and (max-width:1220px) {
    .pie-chart-section {
        .pie-chart {
            height: 320px !important;
            width: 320px !important;
        }
    }
}

.admin-dashboard {
    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }
}

.admin-principal-container {
    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }

}

.admin-teacher-container {
    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }
}

.admin-groups-container {
    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }

    .toast {
        margin-top: -45px !important;
        position: absolute !important;
    }

    .toast-overlay {
        position: relative !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        z-index: 1000 !important;
    }
}

@media (max-width: 450px) {
    .admin-groups-container  {
        .p-toast .p-toast-message .p-toast-message-content {
            padding: 0.5rem;
        }
        .p-toast .p-toast-message.p-toast-message-error {
            width: fit-content !important;
        }
        .toast{
            display: flex !important;
            align-items: center !important;
            justify-content: flex-end !important;
        }
    }
}

.admin-student-container {
    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }
}