.t1-template {
    border: 3px solid #000000;
    padding: 2rem;
    border-radius: 20px;
    min-height: 1000px;
    max-height: 1000px;
    overflow: scroll;
}

// .t1-template-content {
//     min-height: 700px;
//     max-height: 700px;
//     overflow: scroll;
// }

.t1-left {
    // background-color: #052941;
    padding: 20px !important;
    border-right: 1px solid #91BF82;
}

.t1-right {
    padding: 20px 10px !important;
}

.t1-name {
    padding: 10px 0px 10px 0px;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 90% */
}

.t1l-label {
    padding: 10px 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
}

.t1l-details{
    padding: 10px 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.t1personal-details {
    border-bottom: 2px solid #91BF82;
    margin-bottom: 15px;
    word-wrap: break-word !important;
}

.t1r-sections {
    padding: 10px 5px;
}

.t1r-label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    border-bottom: 1px solid #91BF82;
    padding-bottom: 4px;
}

.t1r-content {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    padding-top: 8px;
    overflow-wrap: break-word;
}

.pdf-button {
    margin: 2rem;
    text-align: center;
    position: sticky;
    bottom: 0;
}

.t1r-subcontent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


@media screen and (max-width:767px) {
    .t1-template {
        padding: 1rem;
    }
    .t1personal-details {
        margin-bottom: 10px;
        .t1l-details {
            font-size: 15px;
        }
    }

    .t1-left {
        .t1l-label {
            padding: 5px 0px;
            font-size: 15px;
        }

        .t1l-details {
            font-size: 14px;
        }
    }

    .t1-right {
        .t1r-sections {
            .t1r-label {
                font-size: 15px;
            }
            .t1r-content {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width:600px) {
    .t1-template-content{
        display: grid !important;
    }
    .t1-template {
        padding: 1rem;
        .pdf-button{
            .p-button {
            font-size: 14px !important;
            }
        }
    }
    .resume-templates{
        display: flex !important;
    }
    .t1personal-details {
        margin-bottom: 10px;

        .t1l-details {
            padding: 5px 0px;
            font-size: 12px;
        }
    }

    .t1-left {
        padding: 12px !important;
        border-right: 0px solid #91BF82;
        .t1l-label {
            padding: 5px 0px;
            font-size: 12px;
        }

        .t1l-details {
            padding: 5px 0px;
            font-size: 11px;
        }
    }

    .t1-right {
        padding: 0px 10px !important;
        .t1r-sections {
            padding: 5px 5px;

            .t1r-label {
                font-size: 12px;
            }
            .t1r-content {
                font-size: 11px;
            }
        }
    }
}