.headingD {
  width: 100%;
  height: auto;
  color: #052941 !important;
  font-size: 30px !important;
  margin: auto;
  padding: 0 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-bottom: unset !important;
  font-weight: 100 !important;
}

.main {
  width: 100%;
  height: auto;
  margin: auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
  padding: 40px;

  .main-Sec {
    width: min-content;
    height: auto;
    display: flex;
    flex-direction: column;

    .half-circle {
      margin: auto;
      height: 100px;
      width: 210px;
      border-radius: 130px 130px 0 0;
      border-bottom: transparent;
      position: absolute;
      top: -50px;
    }

    .position {
      background: #ffff;
      width: 100%;
      height: 15vh;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .condent {
      width: auto;
      min-width: 300px;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin: 10px;
      position: relative;
      top: 10px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 6px 15px #ccc;

      span {
        width: -webkit-fill-available;
        height: auto;
        padding: 0 20px;
        position: relative;
        top: -35px;
        text-align: center;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 700px) {
  .headingD {
    justify-content: center !important;
    font-size: 18px !important;
  }
}