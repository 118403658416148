.mob-personalstatement-section {
    margin-top: 150px !important;

    .mob-personal-link {
        a {
            text-decoration: none;
            color: #1575E5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: -2rem;
            margin-bottom: 1rem;
        }
    }

    .mob-personal-heading {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        color: #052941;
    }

    .mob-personal-button {
        .Button {
            background: #3B8A6E !important;
            width: 100%;
            border: #3B8A6E !important;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            padding: 15px;
        }
    }

}

.mob-careergoals-section {
    margin-top: 150px !important;

    .mob-career-link {
        a {
            text-decoration: none;
            color: #1575E5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: -2rem;
            margin-bottom: 1rem;
        }
    }

    .mob-career-heading {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        color: #052941;
    }

    .mob-career-button {
        .Button {
            background: #3B8A6E !important;
            width: 100%;
            border: #3B8A6E !important;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            padding: 15px;
        }
    }

}


.mob-academicgoals-section {
    margin-top: 150px !important;

    .mob-academic-link {
        a {
            text-decoration: none;
            color: #1575E5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: -2rem;
            margin-bottom: 1rem;
        }
    }

    .mob-academic-heading {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        color: #052941;
    }

    .mob-academic-button {
        .Button {
            background: #3B8A6E !important;
            width: 100%;
            border: #3B8A6E !important;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            padding: 15px;
        }
    }

  

}


.mob-academypath-section {
    margin-top: 150px !important;

    .mob-academypath-link {
        a {
            text-decoration: none;
            color: #1575E5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: -2rem;
            margin-bottom: 1rem;
        }
    }

    .mob-academypath-heading {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        color: #052941;
    }

    .mob-academypath-button {
        .Button {
            background: #3B8A6E !important;
            width: 100%;
            border: #3B8A6E !important;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            padding: 15px;
        }
    }

}

.mob-checklist-section {
    margin-top: 150px !important;

    .mob-checklist-link {
        a {
            text-decoration: none;
            color: #1575E5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: -2rem;
            margin-bottom: 1rem;
        }
        h2 {
            margin: 0;
            font-size: 22px;
            text-align: center;
        }
    }

    .mob-checklist-heading {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        color: #052941;
    }

    .mob-checklist-button {
        .Button {
            background: #3B8A6E !important;
            width: 100%;
            border: #3B8A6E !important;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            padding: 15px;
        }
    }

}



.mob-extraactivities-section {
    margin-top: 150px !important;

    .mob-extraactivities-link {
        a {
            text-decoration: none;
            color: #1575E5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: -2rem;
            margin-bottom: 1rem;
        }
    }

    .mob-extraactivities-heading {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        color: #052941;
    }

    .mob-extraactivities-button {
        .Button {
            background: #3B8A6E !important;
            width: 100%;
            border: #3B8A6E !important;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            padding: 15px;
        }
    }


}


@media (max-width: 1008px) {

    .mob-careergoals-section {
        .forms-footer {
            margin: 2rem 0;
            display: flex;
            justify-content: flex-end;
        }
        .m-5 {
            margin: 1rem !important;
        }
    }

    .mob-academicgoals-section {
        .forms-footer {
            margin: 2rem 0;
            display: flex;
            justify-content: space-between;
        }
    }

    .mob-academypath-section {   
        .forms-footer {
            margin: 2rem 0;
            display: flex;
            justify-content: space-between;
        }
    }

    .mob-extraactivities-section {
        .forms-footer {
            margin: 2rem 0;
            display: flex;
            justify-content: space-between;
        }
    }
}

@media (max-width: 500px) {

    .mob-personalstatement-section{
        .m-5 {
            margin: 1rem !important;
        }
        .mob-personal-heading {
            font-size: 20px;
            display: none;
        }
    }

    .mob-careergoals-section {
        .mob-career-heading {
            font-size: 20px;
            display: none;
        }
    }

    .mob-academicgoals-section {
        .m-5 {
            margin: 1rem !important;
        }

        .mob-academic-heading {
            font-size: 20px;
            display: none;
        }
    }

    .mob-academypath-section {   
        .m-5 {
            margin: 1rem !important;
        } 
        .mob-academypath-heading {
            font-size: 20px;
            display: none;
        }
    }

    .mob-extraactivities-section {
        .m-5 {
            margin: 1rem !important;
        }
        .mob-academic-heading {
            font-size: 20px;
            display: none;
        }
    }

    .mob-checklist-heading {
        display:none
    }
    .mob-extraactivities-heading {
        display: none;
    }
}

.mob-checklist-section {
    margin-top: 150px !important;

    .mob-checklist-link {
        a {
            text-decoration: none;
            color: #1575E5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
        }
    }

    .mob-checklist-heading {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        color: #052941;
    }

    .mob-checklist-button {
        .Button {
            background: #3B8A6E !important;
            width: 100%;
            border: #3B8A6E !important;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            padding: 15px;
        }
    }

    .m-5 {
        margin: 1rem !important;
    }

}

@media screen and (max-width:1008px) {
    .extra-activities-form{
        margin: 0px !important;
    }
    .checklist-card-section{
        .p-card .p-card-body {
            padding: 0.5rem !important;
        }
    }
}

@media screen and (max-width: 500px) {
    .checklist-card-section{
        .p-card .p-card-body {
            padding: 0 !important;
        }
    }
}