.error .p-inputtext {
    border: 1px solid #E04A4A !important;
}

.email-error {
    border: 1px solid #E04A4A !important;
}

.login-container {
    width: 100%;
    height: 100%;

    .spinner-section {
        .spinner-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .p-progress-spinner-circle {
            stroke: #052941 !important;
            stroke-width: 5 !important;
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, 6s ease-in-out infinite !important;
        }
    }

    .logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
            width: 200px;
            height: auto;
            padding: 20px;
        }

    }


    .login-card-section {
        .image-section {
            background-color: #ea8254;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }

        .errorLable {
            height: 30px;
            font-size: 14px;
            color: #E04A4A;
            display: flex;
            align-items: center;
        }

        .button {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .resend {
                height: 5vh;
                display: flex;
                justify-content: end;
                align-items: center;
                color: #052941;

                span {
                    cursor: pointer;
                }
            }
        }
    }

    .login-section {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .p-fluid .p-password {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .p-password .p-password-show-icon, .p-password .p-password-hide-icon {
            margin-right: 20px !important;
        }
    }

    .login-section-sec {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .login{
            .p-fluid .p-password {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
            .p-password .p-password-show-icon, .p-password .p-password-hide-icon {
                margin-right: 20px !important;
            }
        }
    }

    .login {
        width: 100%;

        p {
            font-size: 24px;
            color: #000;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .login-sub p {
            font-size: 20px;
        }

        .p-fluid {
            // display: grid;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .field,
            button,
            .forgetpassword {
                margin: unset;

                label{
                    height: 5vh;
                    align-items: center;
                    margin: unset;
                }
            }

            .error-msg {
                margin-top: -25px;
            }
        }


        .field {
            label {
                display: flex;
                font-size: 20px;
                color: #000;
            }

            ::placeholder {
                color: #808080;
                font-size: 16px;
                opacity: 1;
            }
        }

        .button {
            margin-top: 1rem;

            Button {
                background-color: #0a1b33;
                color: white;
                border-radius: 10px;
                font-size: 16px;
                border: none;
            }
        }

        .forgetpassword {
            font-size: 16px;
            display: flex;
            justify-content: flex-end;
            margin-top: 0.4rem;


            a {
                text-decoration: none;
                color: #052941;
                margin: 10px 0;
            }
        }

    }
}

.login-container .p-card .p-card-body {
    padding: 0 !important;
}

.login-container .p-card .p-card-content {
    padding: 0 !important;
}

.p-message-wrapper {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    font-size: 14px !important;
}



@media (max-width: 1000px) {
    .login-container {


        .m-7 {
            margin: 2rem !important;
        }
    }
}

@media (max-width: 767px) {
    .login-container .login-card-section {

        .image-section {
            display: none;
        }

        .login .login-sub p {
            font-size: 18px;
        }
    }
}

@media (max-width: 500px) {
    .login-container {
        position: fixed;

        .login-card-section {
            margin: -7px !important;
            box-shadow: none !important;
            border-top: 1px solid #052941B2;
        }

        .p-card .p-card-body {
            box-shadow: none !important;
        }

    }

    .field .p-inputtext {
        font-size: 15px !important;
    }
}