.laulama-footer-section {
  background-color: #052941;
  color: white;
  width: 100%;
  img {
    width: 170px !important;
  }
  .footer-logo {
    .p-divider-horizontal {
      width: 81% !important;
    }
  }
}

.quick-links,
.legals,
.social-media {
  ul {
    list-style: none;
    padding: 0;
  }

  ul li {
    padding: 8px 0;
  }
  a {
    text-decoration: none;
    color: #ffffff;
  }
}

@media (max-width: 900px) {
  .laulama-footer-section {
   
    &.grid {
      display: grid !important;
      justify-content: center;
    }
  } 
}

@media (max-width: 440px) {
    .laulama-footer-section{
        .footer-logo {
            width: 274px !important;
            .p-divider-horizontal {
                width: 100% !important;
              }
          }
    }
 
  .quick-links h4,
  .legals h4,
  .social-media h4 {
    margin-top: -14px; 
   
  }


.quick-links,
.legals,
.social-media {
  ul li {
    padding: 5px 0;
  }
}
  
}
