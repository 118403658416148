.vision-view {
    display: flex;
    justify-content: center;
    align-items: center;

    .vision-message {
        .vision-first-section{
               span{
                display: grid;
               }
        }

        span {
            font-family: Poppins;
            font-size: 30px;
            font-weight: 600;
            color: rgba(5, 41, 65, 1);
            border-bottom: 5px solid rgba(59, 138, 110, 1);

        }

        p {
            font-family: Poppins;
            font-size: 20px;
            color: rgba(5, 41, 65, 1);
        }
    }

    .vision-option {
        .vision-time {
            margin-left: -60px;
        }

        .vision-target {
            margin-left: -90px;
        }

        .vision-book {
            margin-left: 20px;
        }
    }
}

@media (max-width: 1500px) {
    .vision-view {
        .vision-message {

            span {
                font-size: 24px;
            }

            p {
                font-size: 16px;
                width: 90%;
            }
        }
    }
}

@media (max-width: 1000px) {
    .vision-view {
        display: grid !important;
        justify-content: center;
        align-items: center;

    }

    .vision-view .vision-message p {
        width: 100% !important;
        text-align: justify;
    }

    .vision-section{
        .vision-message{
            display: grid !important;
        }
    }
}


@media (max-width: 700px) {

    .vision-option {
        .vision-cap {
            margin-left: -65px !important;
        }

        .vision-target {
            margin-left: -64px !important;
        }

        .vision-book {
            margin-left: -58px !important;
        }

        img {
            width: 4rem;
            height: 5rem;
        }

        p {
            font-size: 15px;
        }

        .pb-4 {
            padding-bottom: 0rem !important;
        }
    }

    .vision-image {
        img {
            width: 150px;
        }
    }

    .vision-section .m-5 {
        margin: 1rem !important;
    }
}

@media (max-width: 400px) {

    .vision-message .vision-option {
        .vision-cap {
            margin-left: -15px !important;
        }

        .vision-time {
            margin-left: -15px !important;
        }

        .vision-target {
            margin-left: -15px !important;

        }

        .vision-book {
            margin-left: -15px !important;

        }
        p {
            font-size: 12px !important;
        }

        .pb-4 {
            padding-bottom: 0rem !important;
        }
    }

    .vision-image {
        img {
            width: 120px;

        }
    }

}