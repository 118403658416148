.GR-form {
    margin: 1rem 1rem 4rem 1rem;
}

.category-btn {
    border-radius: 10px;
    border: 3px solid #052941;
    padding: 20px;
    color: #052941;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
}

.category-btn-active {
    border-radius: 10px;
    border: 3px solid #052941;
    background: #052941;
    padding: 20px;
    color: #F9F9F9;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
}

.categories {
    display: flex;
    gap: 60px !important;
}

.elective-subjects {
    margin-top:20px;
    width: 100%;
    .p-accordion .p-accordion-header .p-accordion-header-link {
        border-bottom: 4px solid #EA8254 !important;
    }
    .header-icon {
        color: #EA8254;
        font-size: 24px !important;
    }
    .p-accordion .p-accordion-content {
        border-bottom: 4px solid #EA8254 !important;
    }
    .p-accordion-header-text{
        font-size: 24px;
    }
}


@media screen and (max-width:1008px) {
    .top-section-buttons.flex {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
    .GR-form {
      display: none;
    }
    .mob-categories {
      display: grid !important;
      gap: 20px;    
    } 
    .mob-academy-label{
        color: #000000 !important;   
        font-weight: 600;   
        margin-top: 20px !important;  
    }
    .dividee{
        border-bottom: 2px solid #052941;
        display: flex !important;       
    }
    .mob-electives-section.flex{
        display: grid !important;     
    }
}

@media screen and (max-width:500px) {
    .top-section-buttons.flex {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;         
    }
}