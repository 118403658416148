body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-component {
  font-family: 'Poppins', sans-serif !important;
  color: #052941 !important;
}

// Button
.p-button {
  color: #ffffff !important;
  background: #052941 !important;
  border: 1px solid #052941 !important;
  padding: 0.5rem 1rem;
  font-size: 18px !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px !important;
  font-weight: 500 !important;
}

.p-button:focus {
  box-shadow: 0 0 0 0.2rem #495f70 !important;
}

.p-button.p-button-secondary {
  color: #052941 !important;
  border-radius: 10px !important;
  border: 2px solid #000 !important;
  background-color: white !important;
}

.p-button.p-button-secondary:focus {
  border: none !important;
}

.p-button.p-button-success {
  color: white !important;
  border-radius: 10px !important;
  border: 2px solid #052941 !important;
  background-color: #052941 !important;
}

.p-button.p-button-success:focus {
  border: none !important;
}

.p-button.p-button-info {
  color: #052941 !important;
  border-radius: none !important;
  border: none !important;
  background-color: white !important;
}

.p-button.p-button-info:focus {
  border: none !important;
}

.p-button.no-bg-btn {
  background: none !important;
  border: none !important;
  padding: 0 !important;

  a {
    text-decoration: none !important;
    color: #1067B8;
  }
}


.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #052941 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton.p-highlight .p-radiobutton-box {
  background: #052941 !important;
  border-color: #AEB3B7 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover,
.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
  border-color: #052941 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

// Dropdown
.p-dropdown {
  // border-radius: 8px !important;
  // border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
  border: 1px solid #AEB3B7 !important;
  // height: 100% !important;
}

.p-dropdown .p-inputtext {
  border: none !important;
  line-height: 25px !important;
}

.p-dropdown:not(.p.disabled).p-focus {
  box-shadow: 0 0 0 0.2rem rgba(14, 53, 80, 0.245) !important;
  border-color: #052941 !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #052941 !important;
}

.p-dropdown-panel {
  border-radius: 8px !important;
  margin-top: 5px !important;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #AEB3B7 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #d9d9d9;
}

.p-dropdown-items {
  margin: 15px 15px 5px;
}

li.p-dropdown-item {
  border-bottom: 1px solid #d9d9d9 !important;
}

li.p-dropdown-item:last-child {
  border-bottom: none !important;
}

// Card
.p-card {
  box-shadow: 0px 6px 15px #CCCCCC !important;
  border-radius: 10px !important;
}

// Input
.p-inputtext {
  border-radius: 8px !important;
  border: 1px solid #AEB3B7 !important;
  gap: 10px !important;
  font-size: 18px !important;
}

.p-inputtext:enabled:hover {
  border-color: #052941 !important;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem #d9d9d9 !important;
  border-color: #052941 !important;
}



// Checkbox

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #052941 !important;
  background: #052941 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #052941 !important;
}

.p-checkbox:not(.p-checkbox-disabled).p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem #052941 !important
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem #d9d9d9 !important;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #052941 !important;
}

.p-checkbox.p-component.p-checkbox-disabled+label {
  color: #ccc !important;
}

.disabled label {
  color: gray !important;
}

.p-disabled+label {
  color: gray !important;
}

.p-checkbox.p-component.p-checkbox-disabled {
  border-color: #ccc !important;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
  border-color: #052941 !important;
  box-shadow: 0 0 0 0.2rem #d9d9d9 !important;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
  background: #052941 !important;
  border-color: #052941 !important;
}

// Calender

.p-calendar-w-btn-right .p-inputtext {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

label {
  font-size: 18px !important;
}

.p-multiselect {
  border-radius: 8px !important;
  border: 1px solid #AEB3B7 !important;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #052941 !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem rgba(14, 53, 80, 0.245) !important;
  border-color: #052941 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #d9d9d9 !important;
}


.avatar-icon {
  display: inline-block;
  // border-radius: 50%;
  overflow: hidden;
  width: 100px;
}

.avatar-icon:not(:first-child) {
  margin-left: -80px;
}

.avatar-icon img {
  width: 100%;
  display: block;
}

.avatars-label {
  display: flex;
  align-items: center;
  // justify-content: center;
}

.teacher-menu-lists {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.teacher-info {
  display: flex;
  align-items: center;
}

.avatar-container {
  margin-right: 10px;
}

.teacher-list-display {
  display: flex;
  align-items: flex-start;
}

.avatar-list {
  display: flex;
  flex-direction: column;
  // margin-right: 20px;
}

.teacher-names {
  display: flex;
  flex-direction: column;

  li {
    padding: 11px 0;
    list-style: none;
  }
}

.clear-button {
  border: none !important;
  padding: 5px 15px !important;
  border-radius: 20px !important;
  font-size: 16px !important;
}

.p-chip {
  margin-right: 10px;
}

.p-chip .p-chip-text {
  margin-right: 5px;
}


td.full-width-column {
  display: flex;
}

td.full-width-column:last-child {
  margin-bottom: 20px;
}