.subscription {
  padding-top: 10rem;
}

.s-header-text {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #352750;
  padding-left: 4rem;
  margin-bottom: 1rem;
}

.s-header-subtext {
  color: #000000;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 4rem;
}

.subscription-packages {
  .subscription-gold-card {
    margin: 4rem 2rem;
    .p-card .p-card-body {
      width: 35rem;
      height: 21rem;
      background-color: #052941 !important;
      color: #fff;
      padding: 2rem !important;
      border-radius: 10px !important;
    }
    .p-card .p-card-title {
      text-align: center;
      color: #fff;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 126%; /* 60.48px */
    }
    .p-card .p-card-subtitle {
      text-align: center;
      color: #fff;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 126%;
    }
    .descriptions {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 126%; /* 25.2px */
    }
  }
  .subscription-platinum-card {
    margin: 4rem 2rem;
    .p-card .p-card-body {
      width: 35rem;
      height: 21rem;
      background-color: #91bf82 !important;
      color: #000000;
      padding: 2rem !important;
      border-radius: 10px !important;
    }
    .p-card .p-card-title {
      text-align: center;
      color: #000;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 126%; /* 60.48px */
    }
    .p-card .p-card-subtitle {
      text-align: center;
      color: #000;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 126%;
    }
    .descriptions {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 126%; /* 25.2px */
    }
  }
}

.subscription-card {
  width: 35rem;
  height: 21rem;
  border-radius: 10px !important;
  // background: #352750;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.descriptions {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 126%; /* 25.2px */
}

.l-card-button {
  text-align: center;
  margin: 2rem;
}

.r-card-button {
  text-align: center;
  margin: 2rem;
}

.subscription-features {
  margin: 0rem 8rem 3rem 8rem;
  .subscription-table {
    .p-datatable-table {
      border: 1px solid #b9b9b9;
    }
    .p-datatable .p-datatable-thead > tr > th {
      background-color: #052941 !important;
      color: #fff !important;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      line-height: 130%; /* 31.2px */
      border: 1px solid #b9b9b9;
    }
    .p-datatable .p-column-header-content {
      justify-content: center;
    }
    .p-datatable .p-datatable-tbody > tr > td {
      text-align: center;
      font-size: 20px;
      border: 1px solid #b9b9b9;
      .svg {
        width: 20%;
      }
    }
  }
}

.view-btn-section {
  margin: 2rem;
}

.view-button {
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  color: #003087;
}

@media screen and (max-width: 1200px) {
  .subscription {
    margin-top: 0rem;
  }
}

@media screen and (max-width: 850px) {
  .subscription {
    margin-top: 0rem;
  }
  .subscription-header {
    flex-wrap: wrap;
  }
  .subscription-platinum-card {
    .p-card .p-card-body {
      width: 25rem !important;
      height: 30rem !important;
    }
  }
  .subscription-gold-card {
    .p-card .p-card-body {
      width: 25rem !important;
      height: 30rem !important;
    }
  }
  .subscription-card {
    width: 25rem !important;
    height: 30rem !important;
  }
  .s-header-text {
    text-align: center;
    font-size: 35px;
    margin: 1rem 2rem !important;
    padding-left: 1rem;
  }
  .s-header-subtext {
    font-size: 20px;
    margin: 0rem 2rem;
    padding-left: 1rem;
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  .subscription-features {
    margin: 0rem 3rem 3rem 3rem;
  }
}

@media screen and (max-width: 490px) {
  .subscription {
    margin-top: 0rem;
  }
  .s-header-image {
    display: none;
  }
  .s-header-text {
    font-size: 24px;
    margin: 2rem 2rem 0rem 2rem !important;
    padding-left: 0rem;
    line-height: 45px;
  }
  .s-header-subtext {
    font-size: 18px;
    margin: 0rem 2rem;
    padding-left: 0rem;
    line-height: 45px;
  }

  .subscription-platinum-card {
    .p-card .p-card-body {
      width: 20rem !important;
      height: 30rem !important;
    }
  }
  .subscription-gold-card {
    .p-card .p-card-body {
      width: 20rem !important;
      height: 30rem !important;
    }
  }
  .subscription-card {
    width: 20rem !important;
    height: 30rem !important;
  }

  .subscription-packages {
    .subscription-gold-card {
      .p-card .p-card-title {
        font-size: 30px;
      }
      .p-card .p-card-subtitle {
        font-size: 24px;
      }
    }
    .subscription-platinum-card {
      .p-card .p-card-title {
        font-size: 30px;
      }
      .p-card .p-card-subtitle {
        font-size: 24px;
      }
    }
  }
  .what {
    font-size: 20px;
  }

  .service-item {
    margin: 20px 0px;
  }

  .subscription-list {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .subscription-card {
    width: 18rem;
  }
}
