@media screen and (max-width:1008px) 
{
    .college-section-4{
     display: none;
    }
    .graduation-container{
        font-size: 18px !important;
    }
 }
 @media screen and (max-width:500px){
   
    .graduation-container{
        font-size: 16px !important;
    }

    .checklist-card-section .p-button {
        font-size: 16px !important;
    }
 }