.aboutus-section {
    .aboutus-img {
        position: relative;

        .bg {
            position: relative;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(5, 41, 65, 0.5);
            }

            img {
                width: 100%;
                min-height: 255px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: bottom;
                display: flex;
                align-items: center;
                // margin-top: 110px;
            }

            .aboutus-para-section {

                .quote-icon {
                    color: rgba(255, 255, 255, 0.5);
                    font-size: 3rem;
                    margin: 0 20px;
                }

                p {
                    width: 70%;
                    position: absolute;
                    top: 40%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-family: Poppins;
                    font-size: 32px;
                    font-style: italic;
                    text-align: center;
                    color: rgba(255, 255, 255, 1);
                }
            }

            .aboutus-name {
                p {
                    position: absolute;
                    top: 70%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-family: Poppins;
                    font-size: 20px;
                    text-align: center;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
}


@media (max-width: 1500px) {
    .aboutus-section {
        .aboutus-para-section {
            .quote-icon {
                font-size: 2rem !important;
                margin: 0 10px !important;
            }

            p {
                font-size: 25px !important;
            }
        }

        .aboutus-name {
            p {
                top: 75% !important;
            }
        }
    }
}

@media (max-width: 1000px) {
    .aboutus-section {
        .aboutus-para-section {
            .quote-icon {
                font-size: 2rem !important;
                margin: 0 10px !important;
            }

            p {
                width: 80% !important;
                font-size: 18px !important;
            }
        }
    }

    .aboutus-name {
        p {
            font-size: 16px !important;
        }
    }
}


@media (max-width: 500px) {
    .aboutus-section {
        .aboutus-para-section {
            .quote-icon {
                font-size: 1rem !important;
                margin: 0 5px !important;
            }

            p {
                width: 90% !important;
                font-size: 16px !important;
            }
        }
    }

    .aboutus-name {
        p {
            font-size: 14px !important;
            width: 100% !important;
        }
    }
}