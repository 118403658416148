.testimonials-mobile {
  display: none;
}

.testimonials-section {
  padding-top: 130px;
  margin-bottom: 2rem;

  .testimonials-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2rem 0rem 2rem;
    padding: 0px 30px;

    h2 {
      margin: 0;
      font-size: 24px;
      padding-left: 1em;
    }

    .p-dropdown {
      width: auto;
    }
  }

  .testimonials-content {
    display: flex;
    gap: 4rem;

    .testimonial-list {
      width: 50%;
      max-height: 600px;
      overflow-y: auto;
      padding: 10px 30px;

      .testimonial-entry {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 2em;
        padding-left: 1em;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f4f4f4;
        }
        .testimonial-info {
          margin-left: 20px;
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .testimonial-name {
            font-weight: bold;
            margin: 0;
            font-size: 0.85em;
            color: black;
          }

          .testimonial-role {
            font-size: 0.9em;
            color: #555;
          }

          .testimonial-rating {
            .p-rating-icon {
              color: gold;
            }
          }
        }
      }
    }

    .testimonialpage-title {
      color: #052941;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .selected-testimonial {
      width: 70%;
      padding: 1em;

      .selected-testimonial-detail {
        width: 100%;
        display: flex;
        flex-direction: column;

        .testimonial-description {
          h3 {
            font-size: 1.5em;
            margin-bottom: 0.5em;
          }

          p {
            font-size: 1em;
            margin-bottom: 1em;
          }
        }
      }
    }
  }

  .testimonial-image {
    .img {
      width: 13% !important;
      border-radius: 50%;
      margin-right: 1em;
    }
  }

  .highlighted {
    border-radius: 10px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
}

.s-title {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 126%; /* 20.16px */
  margin-left: 20px;
}

.s-subtitle {
  color: rgba(0, 0, 0, 0.50);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 20.16px */
  margin-left: 20px;
}

.t-reviews {
  margin: 0 3rem !important;
}

.tm-review {
  height: 300px;
  padding: 2rem;
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 5px 14px 0px rgba(0, 0, 0, 0.1),
    13px 22px 26px 0px rgba(0, 0, 0, 0.09),
    29px 49px 34px 0px rgba(0, 0, 0, 0.05),
    52px 88px 41px 0px rgba(0, 0, 0, 0.01), 81px 137px 45px 0px rgba(0, 0, 0, 0);
}

.sort-dropdown {
  text-align: right;
  padding-right: 20px;
}

.review-m-title {
  color: #052941;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 15px;
}

.t-mobile-desc {
  color: #052941;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 100%;
}

@media screen and (max-width: 1500px) {
  .tm-review {
    height: 330px;
  }
}

@media screen and (max-width: 1400px) {
  .tm-review {
    height: 350px;
  }
}

@media screen and (max-width: 1300px) {
  .tm-review {
    height: 370px;
  }
}

@media screen and (max-width: 1200px) {
  .tm-review {
    height: 290px;
  }
}


